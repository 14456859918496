<template>
  <div class="w-screen h-screen bg-primary">
    <div
      class="flex flex-col items-center justify-center flex-1 h-full px-4 sm:px-0"
    >
      <div
        class="flex w-full bg-white shadow-lg rounded-mine sm:w-3/4 lg:w-3/4 sm:mx-0"
        style="height: 500px"
      >
        <div
          class="hidden md:block md:w-1/2 rounded-l-mine"
          style="
            background-image: url('https://images.unsplash.com/photo-1449247666642-264389f5f5b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60');
            background-size: cover;
            background-position: center center;
          "
        ></div>
        <div class="flex flex-col w-full px-4 md:w-1/2">
          <div class="flex flex-col items-center justify-center flex-1">
            <img class="w-40 h-40 mb-4" src="@/assets/img/cosinelogo.svg" />
            <h1 class="mb-4 text-4xl font-bold text-center font-mine">
              مرحباً بك
            </h1>
            <div class="w-full">
              <div class="flex flex-col mb-4">
                <input
                  id="email"
                  type="text"
                  class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  name="email"
                  v-model="email"
                  placeholder="Email / الايميل"
                />
              </div>
              <div class="flex flex-col mb-4">
                <input
                  id="password"
                  type="password"
                  class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  name="password"
                  v-model="password"
                  placeholder="Password / كلمة السر"
                />
              </div>

              <div class="flex flex-col mb-4">
                <button
                  @click="signIn()"
                  class="px-4 py-2 mb-2 text-sm font-bold text-white rounded bg-primary font-mine"
                >
                  سجل الدخول
                </button>
                <div
                  v-if="error"
                  class="block px-2 text-sm font-bold text-center text-red-700 font-mine"
                >
                  الرجاء التحقق من معلومات الدخول
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auth } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
export default {
  data() {
    return {
      email: null,
      password: null,
      error: null,
    };
  },
  methods: {
    signIn() {
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.replace({ name: "Dashboard" });
        })

        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>